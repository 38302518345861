import React from 'react';
import 'bulma/css/bulma.min.css';



function Footer() {
    return (
        <footer className="footer is-fluid">
            <div className="content has-text-centered">
                <p>2024 by Jan Fath</p>
            </div>
        </footer>
    );
}

export default Footer;