import React from 'react';
import 'bulma/css/bulma.min.css';
import { useState } from 'react';


function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }    

    return (
        <nav className='navbar' role='navigation' aria-label='main navigation'>
            <div className="navbar-brand">
                <a href="/" className="navbar-item">JAN FATH</a>
                <a href="/123" onClick={toggleMenu} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="mainNavBar">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="mainNavBar" className={'navbar-menu' + (menuOpen ? ' is-active' : '')}>
                <div className="navbar-start">
                    <a href="/projects" className="navbar-item">Projekte</a>
                    <a href="/2" className="navbar-item">Lebenslauf</a>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <a href="/login" className="button is-primary">Login</a>
                            <a href="/register" className="button is-light">Registrieren</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
