import React from 'react';
import 'bulma/css/bulma.min.css';

interface ProjectBoxProps {
    title: string;
    shortDescription: string;
    roleOrDuty?: string;
    startYear: number;
    endYear?: number
    learnedSkills?: string[];
}

function ProjectBox(props: ProjectBoxProps) {
    return (
        <div className="box">
            <h4 className="title is-4">{props.title}</h4>
            <h5 className="subtitle is-5">{props.startYear}{props.endYear ? ' - ' + props.endYear : ''}{props.roleOrDuty ? ': ' + props.roleOrDuty : ''}</h5>
            <p className="block is-italic">{props.shortDescription}</p>
            <hr />
            {props.learnedSkills ? 
                <div className="tags">
                    {props.learnedSkills.map((lsk) => { 
                        return (
                        <span className="tag">{lsk}</span>
                    )})}
                </div>
            : ''}
        </div>
    );
}

export default ProjectBox;