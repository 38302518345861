import React from 'react';
import 'bulma/css/bulma.min.css';
import MainLayout from '../components/Layout';
import ProjectBox from '../components/ProjectBox';



function ProjectsPage() {
  return (
    <MainLayout>
        <h1 className="title is-1">Projekte</h1>
        <h4 className="subtitle is-4">Ein Überblick über meine bisherigen Stationen</h4>
        <div className="fixed-grid has-2-cols">
            <div className="grid">
                <div className="cell">
                    <ProjectBox 
                        title='Breadfish Roleplay' 
                        shortDescription='Meine ersten Schritte in der Software-Entwicklung habe ich bereits in jungen Jahren beim seinerzeits größten deutschen GTA SA:MP Roleplay-Server machen können. Hier hatte ich erste Berührungspunkte mit Quellcode-Verwaltung, mit der - für mich - ersten Programmiersprache PAWN, sowie mit Issues, User Stories und allem was dazugehört.' 
                        startYear={2015} 
                        endYear={2018}
                        roleOrDuty='GameMaster, Gameserver-Entwickler'
                        learnedSkills={['Quellcodeverwaltung', 'Pawn', 'Issue-Management']}/>
                </div>
                <div className="cell">

                </div>
                <div className="cell">
                </div>

                <div className="cell">

                </div>
                
                <div className="cell">
                    <div className="box">Test</div>
                </div>
                
                <div className="cell">

                </div>
                <div className="cell">

                </div>
                <div className="cell">
                    <div className="box">Test</div>
                </div>
                <div className="cell">
                    <div className="box">Test</div>
                </div>
            </div>
        </div>
    </MainLayout>
  );
}

export default ProjectsPage;
