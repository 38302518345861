import 'bulma/css/bulma.min.css';
import MainLayout from '../components/Layout';

function StartPage() {
  return (
    <MainLayout>
            <h1 className="title is-1">Jan Fath</h1>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit optio sapiente, maxime esse voluptatibus, cumque voluptates quis porro doloremque quas aut iure dolor dolores, molestias explicabo. Numquam voluptate nesciunt quidem!</p>
    </MainLayout>
  );
}

export default StartPage;
